const messages = {
    'en': {
        // Common //////////
        'common_action_close': 'Close',
        // Error General //////////
        'Something went wrong. Please contact us.': 'Something went wrong. Please contact us.',
        'Something went wrong.': 'Something went wrong.',
        'common_state_error_sww': 'Something went wrong',
        // Website //////////
        'Website': 'Website',
        'Features': 'Features',
        'Services': 'Services',
        'Contact': 'Contact',
        // Website LINKS //////////
        'Website_URL': 'https://www.cloutlayer.com',
        'Features_URL': 'https://www.cloutlayer.com/product/',
        'Services_URL': 'https://www.cloutlayer.com/services/',
        'Contact_URL': 'https://www.cloutlayer.com/contact/',
        'TermsOfService_URL': 'https://www.cloutlayer.com/legal/terms-of-service/',
        'PrivacyPolicy_URL': 'https://www.cloutlayer.com/legal/privacy-policy/',
        // Marketing //////////
        'slogan_01_first_part': 'Your foundation for',
        'slogan_01_second_part': 'communication, influence, growth',
        'Get started with Cloutlayer': 'Get started with Cloutlayer',
        'Your foundation for communication, influence, growth': 'Your foundation for communication, influence, growth',

        'Create account or contact sales': 'Create account or contact sales',
        'Cloutlayer': 'Cloutlayer',
        'Cloutlayer.': 'Cloutlayer.',
        'All Rights Reserved': 'All Rights Reserved',
        'All Rights Reserved.': 'All Rights Reserved.',
        'Have an account?': 'Have an account?',
        'Don\'t have an account?': 'Don\'t have an account?',
        'Sign Up': 'Sign Up',
        'Sign up': 'Sign up',
        'Login': 'Login',
        'Log in': 'Log in',
        'Log In': 'Log In',
        'Logout': 'Logout',
        'Log Out': 'Log Out',
        'Log out': 'Log out',
        'Logging Out': 'Logging Out',
        'Forgot Password?': 'Forgot Password?',
        'Remember me': 'Remember me',
        'Remember Me': 'Remember Me',
        'Login failed': 'Login failed',
        'Login Failed': 'Login Failed',
        'Privacy Policy': 'Privacy Policy',
        'Terms of Use': 'Terms of Use',
        'Terms Of Use': 'Terms Of Use',
        'Step': 'Step',
        'of': 'of',
        'Step 1': 'Step 1',
        'Step 2': 'Step 2',
        'Step 3': 'Step 3',
        // @future : Do we need them? Set and translate.
        'Reg_1_Step_1_Title': 'Step 1: Your Information',
        'Reg_1_Step_1_Subtitle': '__SUBTITLE__',
        'Reg_1_Step_2_Title': 'Step 2: Your Account',
        'Reg_1_Step_2_Subtitle': '__SUBTITLE__',
        'Reg_1_Step_3_Title': 'Step 3: Payment',
        'Reg_1_Step_3_Subtitle': '__SUBTITLE__',
        'Your Information': 'Your Information',
        'Your Account': 'Your Account',
        'Payment': 'Payment',
        'First Name': 'First Name',
        'Last Name': 'Last Name',
        'Email': 'Email',
        'Phone Number': 'Phone Number',
        'Account Name': 'Account Name',
        'Account ID (alias)': 'Account ID (alias)',
        'Account_Alias_Help_Text': 'Your account ID (alias) must be 2-60 characters long and can contain only lowercase letters. Example: "acmecompany"',
        'Username': 'Username',
        'Email or Username': 'Email or Username',
        'Password': 'Password',
        'Password Repeat': 'Password Repeat',
        'Repeat Password': 'Repeat Password',
        'Password_Validation_1': 'The password must be 8-32 characters long and must contain at least: 1 latin uppercase letter, 1 latin lowercase letter, 1 number, and one special character (@ ! # etc)',
        'Password_Validation_No_Match_1': 'Passwords do not match',
        'Language': 'Language',
        'Default Language': 'Default Language',
        'Okay, next': 'Okay, next',
        'Previous': 'Previous',
        'Accept_TOS': 'I accept the Terms of Use',
        'Accept_Marketing_Communication': 'I want to receive offers, tutorials, tips and tricks, business news and other interesting stuff from Cloutlayer',
        'Get Cloutlayer': 'Get Cloutlayer',
        'Activate Account': 'Activate Account',
        'Account Successfully Activated': 'Account Successfully Activated',
        'Account Activation Failed': 'Account Activation Failed',
        'See you later': 'See you later',
        'Welcome back': 'Welcome back',
        'Well done': 'Well done',
        'Email_Confirmation_Link': 'Please check your inbox for the email confirmation link',
        'Email_Confirmation_Link_SPAM_Note': 'If you do not receive the confirmation message within a few minutes of signing up, please check your Spam or Bulk E-Mail folder just in case the confirmation email got delivered there instead of your inbox. If so, select the confirmation message and mark it Not Spam, which should allow future messages to get through.',
        'Open Gmail': 'Open Gmail',
        'Open Yahoo': 'Open Yahoo',
        'Open Outlook': 'Open Outlook',
        'Open iCloud': 'Open iCloud',
        'Registration_email_exists': 'Email is already in use',
        'Registration_username_exists': 'Username is already in use',
        'Registration_account_alias_exists': 'Account Alias is already in use',
        'Registration_failed': 'Registration Failed. Please try again later or contact support',
        'account': 'account',
        'accounts': 'accounts',
        'Account': 'Account',
        'Accounts': 'Accounts',
        'Profile': 'Profile',
        'No Accounts Found': 'No Accounts Found',
        'Please contact us': 'Please contact us',
        'Okay': 'Okay',
        // Profile Edit //////////
        'Profile Saved': 'Profile Saved',
        'Edit Profile': 'Edit Profile',
        'Edit': 'Edit',
        'Save': 'Save',
        'Profile Update Failed': 'Profile Update Failed',
        'Please try again later': 'Please try again later',
        // Change Password //////////
        'Password Changed': 'Password Changed',
        'Change Password': 'Change Password',
        'Password Change': 'Password Change',
        'New Password': 'New Password',
        'New Password Repeat': 'New Password Repeat',
        'Change': 'Change',
        'Password Change Failed': 'Password Change Failed',
        'Make sure that the current password is correct': 'Make sure that the current password is correct',
        // Password Reset //////////
        'Password Reset': 'Password Reset',
        'PASSWORD_RESET_INIT_AFTER_REQUEST': 'If a matching profile was found, a message was sent to the provided email to allow you to reset your password.',
        'PASSWORD_RESET_INIT_AFTER_REQUEST_WITH_INFO': 'Instructions have been sent to your e-mail address.',
        'Reset': 'Reset',
        'Set a new password': 'Set a new password',
        'Password Reset Failed': 'Password Reset Failed',
        'Probably the reset key has expired': 'Probably the reset key has expired',
        'Set new password': 'Set new password',
        // Not Found //////////
        'Not Found': 'Not Found',
        // Account //////////
        'Create Account': 'Create Account',
        // Sign Up - New Account //////////
        'Welcome to Cloutlayer': 'Welcome to Cloutlayer',
        'Redirecting to app': 'Redirecting to app',
        'sign_up_v0_new_account_form_customize': 'Customize',
        'SUV0_AddOn_Enabled': 'Enabled',
        'SUV0_AddOn_Disabled': 'Disabled',
        'Customize Cloutlayer': 'Customize Cloutlayer',
        'You can enable or disable the add-ons at any time.': 'You can enable or disable the add-ons at any time.',
        // Sign Up - New User //////////
        'Your information is safe and secure in Cloutlayer': 'Your information is safe and secure in Cloutlayer',
        'sign_up_v0_tos_accept_1_pre': 'By creating a Cloutlayer account, you\'re agreeing to accept the',
        'sign_up_v0_tos_accept_1_post': 'Cloutlayer Terms of Service',
        'sign_up_v0_privacy_statement_pre': 'We\'re committed to your privacy. Cloutlayer uses the information you provide to us to contact you about our relevant content, products, and services. You may unsubscribe from these communications at any time. For more information, check out our',
        'sign_up_v0_privacy_statement_post': 'Privacy Policy.',
        // Add-ons //////////
        'Politics Add-on': 'Politics Add-on',
        'GDPR Add-on': 'GDPR Add-on',
        'i18n Add-on': 'i18n Add-on',
        // Invitation Code //////////
        'Invitation Code': 'Invitation Code',
        'The invitation code is invalid': 'The invitation code is invalid',
        'invitation_code_modal_title': 'Cloutlayer is currently in alpha version.',
        'invitation_code_modal_subtitle': 'If you would like to sign up, please contact us to get the invitation code.',
        'invitation_code_modal_release': 'Estimated release date for public beta version: January 2023',
        'Registration_invitation_code_v0_invalid': 'The invitation code is invalid',
        'Registration_invitation_code_v0_invalid_use_another': 'Use another invitation code'
    },
    'el': {
        // Common //////////
        'common_action_close': 'Κλείσιμο',
        // Error General //////////
        'Something went wrong. Please contact us.': 'Κάτι πήγε στραβά. Παρακαλούμε επικοινωνήστε μαζί μας.',
        'Something went wrong.': 'Κάτι πήγε στραβά.',
        'common_state_error_sww': 'Κάτι πήγε στραβά',
        // Website //////////
        'Website': 'Ιστοσελίδα',
        'Features': 'Χαρακτηριστικά',
        'Services': 'Υπηρεσίες',
        'Contact': 'Επικοινωνία',
        // Website LINKS //////////
        'Website_URL': 'https://el.cloutlayer.com',
        'Features_URL': 'https://el.cloutlayer.com/product/',
        'Services_URL': 'https://el.cloutlayer.com/services/',
        'Contact_URL': 'https://el.cloutlayer.com/contact/',
        'TermsOfService_URL': 'https://el.cloutlayer.com/legal/terms-of-service/',
        'PrivacyPolicy_URL': 'https://el.cloutlayer.com/legal/privacy-policy/',
        // Marketing //////////
        'slogan_01_first_part': 'Το θεμέλιο σας για',
        'slogan_01_second_part': 'επικοινωνία, επιρροή, ανάπτυξη',
        'Get started with Cloutlayer': 'Ξεκινήστε με το Cloutlayer',
        'Your foundation for communication, influence, growth': 'Το θεμέλιό σας για επικοινωνία, επιρροή, ανάπτυξη',

        'Create account or contact sales': 'Δημιουργήστε ένα λογαριασμό ή επικοινωνήστε μαζί μας',
        'Cloutlayer': 'Cloutlayer',
        'Cloutlayer.': 'Cloutlayer.',
        'All Rights Reserved.': 'Με Επιφύλαξη Παντός Δικαιώματος.',
        'Have an account?': 'Έχετε ήδη ένα λογαριασμό;',
        'Don\'t have an account?': 'Δεν έχετε λογαριασμό;',
        'Sign Up': 'Εγγραφή',
        'Sign up': 'Εγγραφή',
        'Login': 'Σύνδεση',
        'Log in': 'Σύνδεση',
        'Log In': 'Σύνδεση',
        'Logout': 'Αποσύνδεση',
        'Log Out': 'Αποσύνδεση',
        'Log out': 'Αποσύνδεση',
        'Logging Out': 'Γίνεται Αποσύνδεση',
        'Forgot Password?': 'Ξεχάσατε τον κωδικό σας;',
        'Remember me': 'Να με θυμάσαι',
        'Remember Me': 'Να με θυμάσαι',
        'Login failed': 'Η σύνδεση απέτυχε',
        'Login Failed': 'Η σύνδεση απέτυχε',
        'Privacy Policy': 'Πολιτική Απορρήτου',
        'Terms of Use': 'Όροι Χρήσης',
        'Terms Of Use': 'Όροι Χρήσης',
        'Step': 'Βήμα',
        'of': 'από',
        'Step 1': 'Βήμα 1',
        'Step 2': 'Βήμα 2',
        'Step 3': 'Βήμα 3',
        // @future : Do we need them? Set and translate.
        'Reg_1_Step_1_Title': 'Βήμα 1: Οι Πληροφορίες σας',
        'Reg_1_Step_1_Subtitle': '__SUBTITLE__',
        'Reg_1_Step_2_Title': 'Βήμα 2: Ο Λογαριασμός σας',
        'Reg_1_Step_2_Subtitle': '__SUBTITLE__',
        'Reg_1_Step_3_Title': 'Βήμα 3: Πληρωμή',
        'Reg_1_Step_3_Subtitle': '__SUBTITLE__',
        'Your Information': 'Οι Πληροφορίες σας',
        'Your Account': 'Ο Λογαριασμός σας',
        'Payment': 'Πληρωμή',
        'First Name': 'Όνομα',
        'Last Name': 'Επώνυμο',
        'Email': 'Email',
        'Phone Number': 'Αριθμός Τηλεφώνου',
        'Account Name': 'Όνομα Λογαριασμού',
        'Account ID (alias)': 'Αναγνωριστικό Λογαριασμού',
        'Account_Alias_Help_Text': 'Το Αναγνωριστικό Λογαριασμού πρέπει να είναι 2 με 60 χαρακτήρες. Μπορεί να περιλαμβάνει μόνο πεζά λατινικά γράμματα. Π.χ. "acmecompany"',
        'Username': 'Όνομα Χρήστη',
        'Email or Username': 'Email ή Όνομα Χρήστη',
        'Password': 'Κωδικός',
        'Password Repeat': 'Επανάληψη Κωδικού',
        'Repeat Password': 'Επανάληψη Κωδικού',
        'Password_Validation_1': 'Ο Κωδικός πρέπει να είναι 8 με 32 χαρακτήρες και να περιλαμβάνει τουλάχιστον 1 λατινικό κεφαλαίο γράμμα, 1 λατινικό πεζό γράμμα, 1 αριθμό και 1 ειδικό χαρακτήρα (@ ! # κ.τ.λ.)',
        'Password_Validation_No_Match_1': 'Οι Κωδικοί δεν ταιριάζουν',
        'Language': 'Γλώσσα',
        'Default Language': 'Προεπιλεγμένη Γλώσσα',
        'Okay, next': 'Εντάξει, επόμενο',
        'Previous': 'Προηγούμενο',
        'Accept_TOS': 'Αποδέχομαι τους Όρους Χρήσης',
        'Accept_Marketing_Communication': 'Θέλω να λαμβάνω προσφορές, συμβουλές, ειδήσεις και άλλα χρήσιμα πράγματα',
        'Get Cloutlayer': 'Αποκτήστε το Cloutlayer',
        'Activate Account': 'Ενεργοποίηση Λογαριασμού',
        'Account Successfully Activated': 'Η ενεργοποίηση του λογαριασμού ολοκληρώθηκε επιτυχώς',
        'Account Activation Failed': 'Η ενεργοποίηση του λογαριασμού απέτυχε',
        'See you later': 'Τα λέμε αργότερα',
        'Welcome back': 'Καλώς ήρθατε και πάλι',
        'Well done': 'Πολύ καλά',
        'Email_Confirmation_Link': 'Παρακαλούμε ελέγξτε τα εισερχόμενά σας για να ενεργοποιήσετε το λογαριασμό σας',
        'Email_Confirmation_Link_SPAM_Note': 'Αν δε λάβετε το επιβεβαιωτικό μήνυμα email εντός ολίγων λεπτών από την εγγραφή σας, παρακαλούμε να ελέγξετε το φάκελο με τα SPAM ή να επικοινωνήστε μαζί μας.',
        'Open Gmail': 'Άνοιγμα Gmail',
        'Open Yahoo': 'Άνοιγμα Yahoo',
        'Open Outlook': 'Άνοιγμα Outlook',
        'Open iCloud': 'Άνοιγμα iCloud',
        'Registration_email_exists': 'Το Email χρησιμοποιείται ήδη',
        'Registration_username_exists': 'Το Όνομα Χρήστη (username) χρησιμοποιείται ήδη',
        'Registration_account_alias_exists': 'Το Αναγνωριστικό Λογαριασμού χρησιμοποιείται ήδη',
        'Registration_failed': 'Η εγγραφή απέτυχε. Παρακαλούμε επικοινωνήστε μαζί μας',
        'account': 'λογαριασμός',
        'accounts': 'λογαριασμοί',
        'Account': 'Λογαριασμός',
        'Accounts': 'Λογαριασμοί',
        'Profile': 'Προφίλ',
        'No Accounts Found': 'Δε βρέθηκε κανένας λογαριασμός',
        'Please contact us': 'Παρακαλούμε επικοινωνήστε μαζί μας',
        'Okay': 'Εντάξει',
        // Profile Edit //////////
        'Profile Saved': 'Το Προφίλ Αποθηκεύτηκε',
        'Edit Profile': 'Επεξεργασία Προφίλ',
        'Edit': 'Επεξεργασία',
        'Save': 'Αποθήκευση',
        'Profile Update Failed': 'Η αποθήκευση του προφίλ απέτυχε',
        'Please try again later': 'Παρακαλούμε δοκιμάστε ξανά αργότερα',
        // Change Password //////////
        'Password Changed': 'Ο Κωδικός Άλλαξε',
        'Change Password': 'Αλλαγή Κωδικού',
        'Password Change': 'Αλλαγή Κωδικού',
        'New Password': 'Νέος Κωδικός',
        'New Password Repeat': 'Νέος Κωδικός (Επανάληψη)',
        'Change': 'Αλλαγή',
        'Password Change Failed': 'Η αλλαγή του κωδικού απέτυχε',
        'Make sure that the current password is correct': 'Σιγουρευτείτε ότι ο κωδικός είναι σωστός',
        // Password Reset //////////
        'Password Reset': 'Επαναφορά Κωδικού',
        'PASSWORD_RESET_INIT_AFTER_REQUEST': 'Αν το email αντιστοιχεί σε κάποιο προφίλ, ένα μήνυμα με οδηγίες για την επαναφορά έχει αποσταλεί.',
        'PASSWORD_RESET_INIT_AFTER_REQUEST_WITH_INFO': 'Έχουν αποσταλεί οδηγίες στο email σας.',
        'Reset': 'Επαναφορά',
        'Set a new password': 'Ορίστε νέο κωδικό',
        'Password Reset Failed': 'Η επαναφορά του κωδικού απέτυχε',
        'Probably the reset key has expired': 'Πιθανόν το ειδικό κλειδί έχει λήξει',
        'Set new password': 'Ορισμός νέου κωδικού',
        // Not Found //////////
        'Not Found': 'Δε Βρέθηκε',
        // Account //////////
        'Create Account': 'Δημιουργία Λογαριασμού',
        // Sign Up - New Account //////////
        'Welcome to Cloutlayer': 'Καλώς ήρθατε στο Cloutlayer',
        'Redirecting to app': 'Γίνεται ανακατεύθυνση στην εφαρμογή',
        'sign_up_v0_new_account_form_customize': 'Παραμετροποίηση',
        'SUV0_AddOn_Enabled': 'Ενεργοποιημένο',
        'SUV0_AddOn_Disabled': 'Απενεργοποιημένο',
        'Customize Cloutlayer': 'Παραμετροποιήστε το Cloutlayer',
        'You can enable or disable the add-ons at any time.': 'Μπορείτε να ενεργοποιήσετε ή να απενεργοποιήσετε τις προσθήκες οποιαδήποτε στιγμή.',
        // Sign Up - New User //////////
        'Your information is safe and secure in Cloutlayer': 'Οι πληροφορίες σας είναι απόρρητες και ασφαλείς στο Cloutlayer',
        'sign_up_v0_tos_accept_1_pre': 'Με τη δημιουργία ενός λογαριασμού Cloutlayer αποδέχεστε τους',
        'sign_up_v0_tos_accept_1_post': 'Όρους Χρήσης',
        'sign_up_v0_privacy_statement_pre': 'Σεβόμαστε την ιδιωτικότητά σας. Το Cloutlayer χρησιμοποιεί τις πληροφορίες σας για να επικοινωνήσει μαζί σας σχετικά με περιεχόμενο που σας ενδιαφέρει, προϊόντα, υπηρεσίες και μοναδικές προσφορές. Μπορείτε να αλλάξετε τις προτιμήσεις επικοινωνίας οποιαδήποτε στιγμή. Για περισσότερες πληροφορίες επισκεφθείτε την',
        'sign_up_v0_privacy_statement_post': 'Πολιτική Απορρήτου.',
        // Add-ons //////////
        'Politics Add-on': 'Προσθήκη Politics',
        'GDPR Add-on': 'Προσθήκη GDPR',
        'i18n Add-on': 'Προσθήκη i18n',
        // Invitation Code //////////
        'Invitation Code': 'Κωδικός Πρόσκλησης',
        'The invitation code is invalid': 'Ο Κωδικός Πρόσκλησης δεν είναι έγκυρος',
        'invitation_code_modal_title': 'Το Cloutlayer είναι προς το παρόν σε alpha έκδοση.',
        'invitation_code_modal_subtitle': 'Αν επιθυμείτε να εγγραφείτε στο Cloutlayer και να αποκτήσετε μοναδικές δυνατότητες από νωρίς, παρακαλούμε επικοινωνήστε μαζί μας.',
        'invitation_code_modal_release': 'Εκτιμώμενη ημερομηνία κυκλοφορίας δημόσιας beta έκδοσης: Ιανουάριος 2023',
        'Registration_invitation_code_v0_invalid': 'Ο Κωδικός Πρόσκλησης δεν είναι έγκυρος',
        'Registration_invitation_code_v0_invalid_use_another': 'Χρήση διαφορετικού κωδικού πρόσκλησης'
    }
};

export default messages;
