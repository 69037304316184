import { getField, updateField } from 'vuex-map-fields';

import user, {
    STOP_USER_FETCHING,
    RESET_USER,
    RESET_USER_ERROR_GET
} from './user.submodule';
import tenantAccount, {
    STOP_TENANT_ACCOUNT_LIST_FETCHING,
    RESET_TENANT_ACCOUNT_LIST,
    RESET_TENANT_ACCOUNT_LIST_ERROR_GET
} from './tenantAccount.submodule';

// Actions [TYPES].
export const ACTION_FLUSH = 'flush';

// State.
const state = {
    ...user.state,
    ...tenantAccount.state
};

// Getters.
const getters = {
    ...user.getters,
    ...tenantAccount.getters,

    getField
};

// Actions.
const actions = {
    ...user.actions,
    ...tenantAccount.actions,

    [ACTION_FLUSH] ({ commit }) {
        // Reset user sub-module.
        commit(STOP_USER_FETCHING);
        commit(RESET_USER);
        commit(RESET_USER_ERROR_GET);

        // Reset tenant account sub-module.
        commit(STOP_TENANT_ACCOUNT_LIST_FETCHING);
        commit(RESET_TENANT_ACCOUNT_LIST);
        commit(RESET_TENANT_ACCOUNT_LIST_ERROR_GET);
    }
};

// Mutations.
const mutations = {
    ...user.mutations,
    ...tenantAccount.mutations,

    updateField
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
