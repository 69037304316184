import Vue from 'vue';

import textEllipsis from '../filters/text-ellipsis.filter';

const AppFilters = {
    init () {
        // Register Filters
        Vue.filter('ellipsis', textEllipsis);
    }
};

export default AppFilters;
