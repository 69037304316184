<script>
import { mapGetters } from 'vuex';
import { AccountService } from '../../common/services/api.service';

const ACCOUNT_STORE_MODULE = 'account';

export default {
    name: 'AccountAccessSharedMixin',
    computed: {
        ...mapGetters(ACCOUNT_STORE_MODULE, {
            // User sub-module //////////
            accountIsAuthenticated: 'isAuthenticated',
            accountUser: 'user',

            // TenantAccount sub-module //////////
            accountTenantAccountListFetching: 'tenantAccountListFetching',
            accountTenantAccountList: 'tenantAccountList',
            accountTenantAccountListLength: 'tenantAccountListLength' // Counts tenantAccounts found by authorities (reliable).
        })
    },
    methods: {
        goToRouteBasedOnAuth (auth = false, routeName = 'profile', replace = false) {
            let isAuth = this.accountIsAuthenticated;

            if (isAuth === auth) {
                if (replace === true) {
                    this.$router.replace({ name: routeName });
                } else {
                    this.$router.push({ name: routeName });
                }
            }
        },

        /**
         * Check if user is authenticated.
         * Request UAA
         * If user is not authenticated, go to logout-silent
         * and this component will flush account and redirect to / (root).
         *
         * D E P R E C A T E D
         */
        async checkAuthRemoteAsync () {
            // No need to redirect if account user is null.
            // @help : We do this because if account user null, the app will perform two unnessary redirects.
            // - one is the previous push
            // - second is the redirect in this method.
            if (!this.accountIsAuthenticated) {
                return;
            }

            // Authenticate.
            const loginResult = await AccountService.whoAmI().then(({ data }) => {
                if (typeof data === 'string') {
                    return data === 'anonymous' ? null : data;
                } else {
                    return null;
                }
            }).catch((reason) => {
                // @help : IMPORTANT : This redirect must never be reached.
                // We expect that the zeus's endpoint always replies.
                return null;
            });

            // If result is null, go to logout-silent.
            if (loginResult === null) {
                this.$router.push({ name: 'logout-silent' });
            }
        }
    }
};
</script>
