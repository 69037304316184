import _ from 'lodash';

import {
    ROLE_USER,
    ROLE_ADMIN,
    ROLE_CLTLR_PREFIX,
    ROLE_CLTLR_SUFFIX,
    ROLE_CLTLR_USER_SUFFIX,
    ROLE_CLTLR_ADMIN_SUFFIX
} from '../config/config';

function onlyUnique (value, index, self) {
    return self.indexOf(value) === index;
}

/**
 * Account Logic (User-Account Logic)
 *
 * @future : Rename and refactor (all projects) : UserLogic
 */
export const AccountLogic = {
    /**
     * Extract tenantId (aliases) from user authorities list.
     *
     * IMPORTANT: The existence of an tenantId does not imply access to tenant.
     */
    extractTenantIdList (account) {
        // Initialize the tenantId.
        let tenantIdList = [];

        // Check account.
        if (account === null) {
            return tenantIdList;
        }

        // Get authorities from account.
        let authorities = _.get(account, 'authorities', []);

        // Check if authorities is empty.
        if (authorities.length === 0) {
            return tenantIdList;
        }

        // Extract tenants from account.
        let tenants = authorities.filter((item) => {
            return !!(item.startsWith(ROLE_CLTLR_PREFIX) && item.endsWith(ROLE_CLTLR_SUFFIX));
        }).map((item) => {
            let cleaned = item;
            if (cleaned.startsWith(ROLE_CLTLR_PREFIX)) {
                cleaned = cleaned.replace(ROLE_CLTLR_PREFIX, '');
            }
            if (cleaned.endsWith(ROLE_CLTLR_SUFFIX)) {
                cleaned = cleaned.replace(ROLE_CLTLR_SUFFIX, '');
            }
            cleaned = cleaned.toLowerCase();
            return cleaned;
        }).filter(onlyUnique).sort(function (a, b) {
            if (a < b) { return -1; }
            if (a > b) { return 1; }
            return 0;
        });

        tenantIdList = tenants;
        return tenantIdList;
    },

    /**
     * Checks if user is in role.
     *
     * @param account the user instance
     * @param role the role string
     * @returns boolean if has role, false otherwise
     */
    hasRole (account, role) {
        // @see : Implementation.
        // - Check role (not null, string)
        // - Clean role (uppercase, append ROLE_ if not exist)
        // Check role.
        if (role === null) {
            return false;
        }

        // role must be string.
        if (typeof role !== 'string') {
            return false;
        }

        // Clean role.
        role = role.trim();
        role = role.toUpperCase();

        // Add ROLE_ if missing.
        if (role.startsWith('ROLE_') === false) {
            role = 'ROLE_' + role;
        }

        // Check Account.
        if (account === null) {
            return false;
        }

        // Check if role exist in Authorities list.
        return _.get(account, 'authorities', []).some(function (item) {
            return item === role;
        });
    },

    /**
     * Given the tenantId, builds the ROLE_CLTLR_{TENANT_ALIAS}_TENANT
     */
    getCloutlayerTenantAuthorityFromTenantId (tenantId) {
        // @future : Validations.
        tenantId = tenantId.trim();
        tenantId = tenantId.toUpperCase();
        return ROLE_CLTLR_PREFIX + tenantId + ROLE_CLTLR_SUFFIX;
    },

    /**
     * Given the tenantId, builds the ROLE_CLTLR_{TENANT_ALIAS}_TENANT_USER
     */
    getCloutlayerTenantUserAuthorityFromTenantId (tenantId) {
        // @future : Validations.
        tenantId = tenantId.trim();
        tenantId = tenantId.toUpperCase();
        return ROLE_CLTLR_PREFIX + tenantId + ROLE_CLTLR_USER_SUFFIX;
    },

    /**
     * Given the tenantId, builds the ROLE_CLTLR_{TENANT_ALIAS}_TENANT_ADMIN
     */
    getCloutlayerTenantAdminAuthorityFromTenantId (tenantId) {
        // @future : Validations.
        tenantId = tenantId.trim();
        tenantId = tenantId.toUpperCase();
        return ROLE_CLTLR_PREFIX + tenantId + ROLE_CLTLR_ADMIN_SUFFIX;
    },

    getRtuAccessLevelList (account, tenantId) {
        // Build roles related to tenant.
        const tenantMembershipRole = AccountLogic.getCloutlayerTenantAuthorityFromTenantId(tenantId);
        const tenantUserRole = AccountLogic.getCloutlayerTenantUserAuthorityFromTenantId(tenantId);
        const tenantAdminRole = AccountLogic.getCloutlayerTenantAdminAuthorityFromTenantId(tenantId);

        // Set flags that declare is user has specific roles.
        const isUser = AccountLogic.hasRole(account, ROLE_USER);
        const isAdmin = AccountLogic.hasRole(account, ROLE_ADMIN);
        const hasTenantMembership = AccountLogic.hasRole(account, tenantMembershipRole);
        const isTenantUser = AccountLogic.hasRole(account, tenantUserRole);
        const isTenantAdmin = AccountLogic.hasRole(account, tenantAdminRole);

        // Initialize access level list.
        return {
            hasTenantUserAccessLevel: isAdmin === true || (isUser === true && hasTenantMembership === true && isTenantUser === true),
            hasTenantAdminAccessLevel: isAdmin === true || (isUser === true && hasTenantMembership === true && isTenantUser === true && isTenantAdmin === true)
        };
    }
};
