import Vue from 'vue';

import vuetiful, { ClvHeadMetaOptions } from 'vuetiful/src/main';

import Logo from '../../components/Logo';
import CoreLoader from '../../components/patterns/CoreLoader';
import ElementLoading from '../../components/loading/ElementLoading';

import CommonBoxLogoV1 from '../../views/Snippets/CommonBoxLogoV1';
import CommonBoxLinksV1 from '../../views/Snippets/CommonBoxLinksV1';
import CommonBoxFooter from '../../views/Snippets/CommonBoxFooter';

const AppComponents = {
    init () {
        // Vuetiful Components Registration //////////
        Vue.use(vuetiful, [
            'ClvHeadMeta'
        ]);
        ClvHeadMetaOptions.setOption('titleOd', 'Cloutlayer');
        ClvHeadMetaOptions.setOption('ods', 'disable');

        // App Global Registration //////////
        Vue.component('logo', Logo);
        Vue.component('core-loader', CoreLoader);
        Vue.component('element-loading', ElementLoading);

        // App Components Global Registration //////////
        Vue.component('common-box-logo', CommonBoxLogoV1);
        Vue.component('common-box-links', CommonBoxLinksV1);
        Vue.component('common-box-footer', CommonBoxFooter);
    }
};

export default AppComponents;
