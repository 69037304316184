<template>
    <sweet-modal :blocking="true"
                 :pulse-on-block="true"
                 :hide-close-button="true"
                 modal-theme="dark"
                 overlay-theme="dark"
                 ref="newUserInviteModal">

        <!-- LOADER 2020-02-04 -->
        <element-loading :active="loading" background-color="rgba(255, 255, 255, 0.1)"></element-loading>

        <!-- LANG KEY -->
        <p class="small text-muted mb-2 user-select-none">
            <span class="cursor-pointer" @click="changeAppLocale('el')">EL</span> / <span class="cursor-pointer" @click="changeAppLocale('en')">EN</span>
        </p>

        <!-- LOGIN -->
        <p class="small mb-4">
            {{ $t('Have an account?' ) }} <router-link tag="a" :to="{ name: 'login' }">{{ $t('Login' ) }}</router-link>
        </p>

        <!-- INFORMATION -->
        <div v-if="withInformation">
            <p class="mb-0">{{ $t('invitation_code_modal_title') }}</p>
            <p>{{ $t('invitation_code_modal_subtitle') }}</p>
            <p class="small"><u>{{ $t('invitation_code_modal_release') }}</u></p>
        </div>

        <!-- FORM -->
        <form class="cltlr-form-label-style-1" v-on:submit.prevent="submitForm" data-vv-scope="invitationCodeV0Form">
            <div class="form-group">
                <input type="text" class="fluid-shadowed-input-1 rounded" name="vv_invitationCodeV0" id="invitationCodeV0" autofocus
                       v-validate="'required|min:2|max:50'" v-model="formFields.invitationCodeV0Code" :placeholder="$t('Invitation Code')"/>
                <span class="small text-danger">{{ errors.first('invitationCodeV0Form.vv_invitationCodeV0Code') }}</span>
            </div>
            <div class="form-group">
                <button type="button" class="btn btn-primary btn-lg btn-block" @click="submitForm">{{ $t('Okay, next') }}</button>
            </div>
        </form>

        <!-- ERRORS -->
        <p class="text-danger font-weight-bold" v-if="error">{{ $t('Something went wrong. Please contact us.') }}</p>
        <p class="text-danger font-weight-bold" v-if="invitationCodeV0Invalid">{{ $t('The invitation code is invalid') }}</p>

        <!-- FOOTER / cloutlayer.com links -->
        <p class="mb-0 text-microcopy--75" v-if="withInformation">
            <a class="text-accent1" :href="$t('Website_URL')">cloutlayer.com</a>
            <span class="ml-2 mr-2">/</span>
            <a class="text-accent1" :href="$t('Features_URL')">{{ $t('Features') }}</a>
            <span class="ml-2 mr-2">/</span>
            <a class="text-accent1" :href="$t('Services_URL')">{{ $t('Services') }}</a>
            <span class="ml-2 mr-2">/</span>
            <a class="text-accent1" :href="$t('Contact_URL')">{{ $t('Contact') }}</a>
        </p>
    </sweet-modal>
</template>

<script>
import AccountAccessSharedMixin from '../../Mixin/AccountAccessSharedMixin';
import { AccountService } from '../../../common/services/api.service';
import { SweetModal } from 'sweet-modal-vue';

export default {
    name: 'InvitationCodeV0ModalAndFormSnippet',
    props: {
        withInformation: {
            type: Boolean,
            default: true,
            required: false
        }
    },
    components: {
        SweetModal
    },
    mixins: [
        AccountAccessSharedMixin
    ],
    data () {
        return {
            // Persistence / Async //////////
            loading: false,
            error: null,
            invitationCodeV0Invalid: false,

            // Form //////////
            formFields: {
                invitationCodeV0Code: ''
            }
        };
    },
    mounted () {
        let self = this;
        setTimeout(function () {
            self.$refs.newUserInviteModal.open();
        }, 700);
    },
    methods: {
        submitForm () {
            this.$validator.validateAll('invitationCodeV0Form').then(valid => {
                if (valid) {
                    this.checkInvitationCode();
                }
            });
        },
        checkInvitationCode () {
            // Reset.
            this.loading = true;
            this.error = null;
            this.invitationCodeV0Invalid = false;

            // Check the code.
            AccountService.checkInvitationCode(this.formFields.invitationCodeV0Code).then(({ data }) => {
                this.error = null;
                if (data === true) {
                    this.$emit('check-success', this.formFields.invitationCodeV0Code);
                    this.$refs.newUserInviteModal.close();
                } else {
                    this.invitationCodeV0Invalid = true; // Code is invalid.
                }
            }).catch((reason) => {
                this.error = reason;
                this.invitationCodeV0Invalid = false; // No need to set to true. An error will be displayed.
            }).finally(() => {
                this.loading = false;
            });
        }
    }
};
</script>
