import Vue from 'vue';
import i18n from '@/common/plugins/i18n';

const DEFAULT_ERROR_MESSAGE_KEY = 'common_state_error_sww';

function cleanMessage (message) {
    if (typeof message === 'string' || message instanceof String) {
        return message.trim();
    } else {
        return '';
    }
}

function translateMessage (message) {
    return i18n.t(message);
}

function getToastedConfigurationForApp (duration = 6000) {
    return {
        duration: duration,
        action: {
            text: i18n.t('common_action_close'),
            onClick: (e, toastObject) => {
                toastObject.goAway(0);
            }
        }
    };
}

function getToastedConfigurationForAppWithPush (pushAction, duration = 6000) {
    return {
        duration: duration,
        action: [
            pushAction,
            {
                text: i18n.t('common_action_close'),
                onClick: (e, toastObject) => {
                    toastObject.goAway(0);
                }
            }
        ]
    };
}

function getToastedMessageWrapper (message) {
    let wrapper = document.createElement('div');
    wrapper.classList.add('toasted-message-wrapper');
    let textNode = document.createTextNode(message);
    wrapper.appendChild(textNode);
    return wrapper;
}

// Not used APIs
// this.$toasted.info(message, options);
// this.$toasted.success(message, options);
const ToastsMixin = {
    methods: {
        toastDefaultWithPush (message, actionText, actionPush, translate = true) {
            // Translate message.
            message = cleanMessage(message);
            message = translateMessage(message);

            actionText = cleanMessage(actionText);
            if (translate) actionText = translateMessage(actionText);

            this.$toasted.show(getToastedMessageWrapper(message), getToastedConfigurationForAppWithPush({
                text: actionText,
                push: actionPush
            }));
        },
        toastDefault (message, translate = true) {
            // Translate message.
            message = cleanMessage(message);
            if (translate) message = translateMessage(message);

            this.$toasted.show(getToastedMessageWrapper(message), getToastedConfigurationForApp());
        },
        toastError (message, translate = true) {
            // Translate message.
            message = cleanMessage(message);
            if (translate) message = translateMessage(message);

            this.$toasted.error(getToastedMessageWrapper(message), getToastedConfigurationForApp(null));
        },
        toastErrorFromError (reason, translate = true) {
            let message;
            if (reason === null || reason === undefined) {
                message = DEFAULT_ERROR_MESSAGE_KEY;
            } else if (typeof reason.dataDetail === 'string' && reason.dataDetail.trim() !== '') {
                message = reason.dataDetail; // back-end message (it should be translated in front-end).
            } else if (typeof reason === 'string' || reason instanceof String) {
                message = reason;
            } else if (reason && reason.stack && reason.message && typeof reason.message === 'string' && reason.message.trim() !== '') {
                // Probably a JS Error.
                message = reason.message;
            } else {
                message = DEFAULT_ERROR_MESSAGE_KEY;
            }

            if (message === null || message === undefined) message = DEFAULT_ERROR_MESSAGE_KEY;
            if (typeof message !== 'string') message = DEFAULT_ERROR_MESSAGE_KEY;
            if (message.trim() === '') message = DEFAULT_ERROR_MESSAGE_KEY;

            // Translate message.
            message = cleanMessage(message);
            if (translate) message = translateMessage(message);

            this.$toasted.error(getToastedMessageWrapper(message), getToastedConfigurationForApp(null));
        }
    }
};

const LocaleMixin = {
    methods: {
        // i18n //////////
        getAppLocale () {
            return i18n.locale;
        },
        changeAppLocale (locale) {
            if (!locale) {
                locale = 'en';
            }
            i18n.locale = locale;
        },
        changeAppLocaleToEl () {
            i18n.locale = 'el';
        },
        changeAppLocaleToEn () {
            i18n.locale = 'en';
        }
    }
};

/**
 * AppMixins (Global Mixin).
 */
const AppMixins = {
    init () {
        Vue.mixin({
            methods: {
                ...ToastsMixin.methods,
                ...LocaleMixin.methods
            }
        });
    }
};

export default AppMixins;
