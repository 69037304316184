<template>
    <div class="sign-up-v0-layout-snippet ViewWrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-5 order-md-1 col-sm-12 order-sm-2 order-2 product-column-section">
                    <div class="row">
                        <div class="col-12">
                            <div class="text-center my-5">
                                <logo :width="'164px'" :color="'#fff'"></logo>
                            </div>
                        </div>
                    </div>
                    <div style="position: absolute; top: 50%; left: 50%; width: 70%; transform: translate(-50%, -50%); text-align: center">
                        <p class="mb-1 h3">{{ $t('slogan_01_first_part') }}</p>
                        <p class="mb-0 h3 font-weight-bolder">{{ $t('slogan_01_second_part') }}</p>
                    </div>
                </div>
                <div class="col-md-7 order-md-2 col-sm-12 order-sm-1 order-1 form-column-section">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * @see Web BG Animation Effects - https://bashooka.com/coding/web-background-animation-effects/
 */
export default {
    name: 'SignUpV0LayoutSnippet'
};
</script>

<style lang="scss">
    .sign-up-v0-layout-snippet {
        position: relative;

        // BG Color fix.
        background-color: #fff;
        &.ViewWrapper {
            background-color: #fff;
        }

        .form-column-section {}

        .product-column-section {
            min-height: 100vh;
            // background-color: #211f32 !important;
            color: #fff !important;

            // background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
            background: linear-gradient(-45deg, #234361, #1070ca, #46b881, #23d5ab);
            background-size: 400% 400%;
            animation: gradient 45s ease infinite;

            @keyframes gradient {
                0% {
                    background-position: 0% 50%;
                }
                50% {
                    background-position: 100% 50%;
                }
                100% {
                    background-position: 0% 50%;
                }
            }
        }

        .form-control {
            border: 1px solid #cbd6e2;
            background-color: #f5f8fa;
            color: #33475b;
            transition: all .15s ease-out;
        }
        label {
            color: #2f3941;
            font-size: 0.8em;
            font-weight: 500;
            user-select: none;
            margin-bottom: 0;
            cursor: pointer;
        }
        .alert {
            border-radius: 0;
        }
        .btn-primary.disabled,
        .btn-primary:disabled {
            cursor: not-allowed;
        }

        .features-list .badge {
            padding: 0.7em 0.8em;
            font-size: 80%;
            font-weight: 400;
            border-radius: 1.5em;
            margin: 0.3em 0.3em 0.3em 0;
            background-color: #e0eae5;
        }

        .btn:hover {
            // -webkit-box-shadow: 0 5px 15px 0 rgba(0,0,0,0.2);
            // box-shadow: 0 5px 15px 0 rgba(0,0,0,0.2);
        }
    }
</style>
