<template>
    <div class="new-account-form-v0-snippet">
        <form class="cltlr-form-label-style-1" v-on:submit.prevent="submit" data-vv-scope="newAccountFormV0">
            <div class="form-group">
                <label for="accountName">{{ $t('Account Name') }}</label>
                <input type="text" class="form-control" name="vv_accountName" id="accountName" autofocus v-validate="'required|min:2|max:50'" v-model="formFields.name">
                <span class="small text-danger">{{ errors.first('newAccountFormV0.vv_accountName') }}</span>
            </div>
            <div class="form-group">
                <label for="accountAlias" v-tooltip="$t('Account_Alias_Help_Text')">{{ $t('Account ID (alias)') }}</label>
                <input type="text" class="form-control" id="accountAlias" name="vv_accountAlias" v-validate="'alpha|required|min:2|max:20'" v-model="formFields.alias">
                <span class="small text-danger">{{ errors.first('newAccountFormV0.vv_accountAlias') }}</span>
            </div>

            <!-- FORM SUBMIT -->
            <div class="form-group mt-2">
                <button type="button" class="btn btn-block btn-accent1" @click="submit">{{ $t('Okay, next') }}</button>
            </div>

            <!-- CUSTOMIZATION OPEN BUTTON -->
            <div class="form-group mt-2 text-center" v-if="formOpenCustomization === false">
                <p class="mb-0 text-primary cursor-pointer small" @click="formOpenCustomization = true">{{ $t('sign_up_v0_new_account_form_customize') }}</p>
            </div>

            <!-- CUSTOMIZATION -->
            <div class="customization-form-part-wrapper mt-4" v-if="formOpenCustomization">
                <p class="mb-0">{{ $t('Customize Cloutlayer') }}</p>
                <p class="text-microcopy--75"><small>{{ $t('You can enable or disable the add-ons at any time.') }}</small></p>
                <!--
                <div class="gk-card mb-2">
                    <div class="d-flex justify-content-between align-items-center">
                        <p class="mb-0">{{ $t('Politics Add-on') }}</p>
                        <toggle-button v-model="formFields.politicsAddOn"
                                       :color="switchControlColor"
                                       :sync="true"
                                       :width="switchControlWidth"
                                       :labels="{ checked: $t('SUV0_AddOn_Enabled'), unchecked: $t('SUV0_AddOn_Disabled') }"/>
                    </div>
                </div>
                -->
                <div class="gk-card mb-2">
                    <div class="d-flex justify-content-between align-items-center">
                        <p class="mb-0">{{ $t('GDPR Add-on') }}</p>
                        <toggle-button v-model="formFields.gdprAddOn"
                                       :color="switchControlColor"
                                       :sync="true"
                                       :width="switchControlWidth"
                                       :labels="{ checked: $t('SUV0_AddOn_Enabled'), unchecked: $t('SUV0_AddOn_Disabled') }"/>
                    </div>
                </div>
                <div class="gk-card mb-2">
                    <div class="d-flex justify-content-between align-items-center">
                        <p class="mb-0">{{ $t('i18n Add-on') }}</p>
                        <toggle-button v-model="formFields.i18nAddOn"
                                       :color="switchControlColor"
                                       :sync="true"
                                       :width="switchControlWidth"
                                       :labels="{ checked: $t('SUV0_AddOn_Enabled'), unchecked: $t('SUV0_AddOn_Disabled') }"/>
                    </div>
                </div>
                <div class="gk-card mb-2">
                    <div class="d-flex justify-content-between align-items-center">
                        <p class="mb-0">
                            <span v-if="formFields.i18nAddOn">{{ $t('Default Language') }}</span>
                            <span v-else>{{ $t('Language') }}</span>
                        </p>
                        <select class="form-control form-control-sm" style="width: 30%" v-model="formFields.defaultLangKey">
                            <option :value="'EN'">English</option>
                            <option :value="'EL'">Greek (ελληνικά)</option>
                        </select>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button';

export default {
    name: 'NewAccountFormV0Snippet',
    props: {
        providedFormOpenCustomization: {
            type: Boolean,
            default: false,
            required: false
        },
        providedFormFields: {
            type: Object,
            default: null,
            required: false
        }
    },
    components: {
        ToggleButton
    },
    data () {
        return {
            switchControlColor: '#82C7EB',
            switchControlWidth: 140,
            formOpenCustomization: false,
            formFields: {
                name: '',
                alias: '',
                defaultLangKey: 'EN', // Set the default. It will be changed.
                politicsAddOn: false,
                gdprAddOn: false,
                i18nAddOn: false
            }
        };
    },
    beforeMount () {
        // Set and then send to parent on submit
        // (parent must remember the state because on async loading this component will be hidden)
        this.formOpenCustomization = this.providedFormOpenCustomization;

        // @future : Make some validations (bools, field types, etc)... (?)
        this.formFields.name = this.providedFormFields.name;
        this.formFields.alias = this.providedFormFields.alias;
        this.formFields.defaultLangKey = this.providedFormFields.defaultLangKey;
        this.formFields.politicsAddOn = this.providedFormFields.politicsAddOn;
        this.formFields.gdprAddOn = this.providedFormFields.gdprAddOn;
        this.formFields.i18nAddOn = this.providedFormFields.i18nAddOn;
    },
    methods: {
        submit () {
            this.$validator.validateAll('newAccountFormV0').then(valid => {
                if (valid) {
                    this.$emit('submit', {
                        valid: true,
                        formFields: this.formFields,
                        formOpenCustomization: this.formOpenCustomization
                    });
                } else {
                    this.$emit('submit', {
                        valid: false,
                        formFields: this.formFields,
                        formOpenCustomization: this.formOpenCustomization
                    });
                }
            });
        }
    }
};
</script>

<style lang="scss">
    .customization-form-part-wrapper {

        .gk-card {

            p {
                color: #234361;
                font-size: 0.85rem;
                font-weight: 600;
                line-height: 1.6rem;
            }
        }
    }
</style>
