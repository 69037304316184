import Vue from 'vue';
import Router from 'vue-router';
import SignUpV0 from './views/SignUp/SignUpV0';
import SignUpV0NewUser from './views/SignUp/SignUpV0NewUser';
import SignUpV0NewAccount from './views/SignUp/SignUpV0NewAccount';
import Login from './views/Login';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        {
            path: '/',
            name: 'root',
            redirect: () => { // You can use 'to => {}'
                return '/login';
            }
        },
        {
            path: '/sign-up',
            name: 'sign-up',
            redirect: () => {
                return '/sign-up/v0';
            }
        },
        {
            path: '/sign-up/v0',
            name: 'sign-up-v0',
            component: SignUpV0
        },
        {
            path: '/sign-up/v0/new-user',
            name: 'sign-up-v0-new-user',
            component: SignUpV0NewUser
            // component: () => import(/* webpackChunkName: "signupv0newuser" */ './views/SignUp/SignUpV0NewUser.vue')
        },
        {
            path: '/sign-up/v0/new-account',
            name: 'sign-up-v0-new-account',
            component: SignUpV0NewAccount
            // component: () => import(/* webpackChunkName: "signupv0newaccount" */ './views/SignUp/SignUpV0NewAccount.vue')
        },
        {
            path: '/login',
            name: 'login',
            // component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
            component: Login
        },
        {
            path: '/logout',
            name: 'logout',
            component: () => import(/* webpackChunkName: "logout" */ './views/Logout.vue')
        },
        {
            path: '/logout/silent',
            name: 'logout-silent',
            component: () => import(/* webpackChunkName: "logoutsilent" */ './views/LogoutSilent.vue')
        },
        {
            path: '/password-reset/init',
            name: 'password-reset-init',
            component: () => import(/* webpackChunkName: "passwordresetinit" */ './views/PasswordResetInit.vue')
        },
        {
            path: '/password-reset/finish',
            name: 'password-reset-finish',
            component: () => import(/* webpackChunkName: "passwordresetfinish" */ './views/PasswordResetFinish.vue')
        },
        {
            path: '/profile',
            name: 'profile',
            component: () => import(/* webpackChunkName: "profile" */ './views/Profile.vue')
        },
        {
            path: '/profile/edit',
            name: 'profile-edit',
            component: () => import(/* webpackChunkName: "profileedit" */ './views/ProfileEdit.vue')
        },
        {
            path: '/profile/password-change',
            name: 'profile-password-change',
            component: () => import(/* webpackChunkName: "profilepasswordchange" */ './views/ProfilePasswordChange.vue')
        },
        {
            path: '/profile/password-reset/init',
            name: 'profile-password-reset-init',
            component: () => import(/* webpackChunkName: "profilepasswordresetinit" */ './views/ProfilePasswordResetInit.vue')
        },
        {
            path: '/accounts',
            name: 'accounts',
            component: () => import(/* webpackChunkName: "accounts" */ './views/TenantAccounts.vue')
        },
        {
            path: '/activate',
            name: 'activate',
            component: () => import(/* webpackChunkName: "activate" */ './views/Activate.vue')
        },
        {
            path: '*',
            name: 'not-found',
            component: () => import(/* webpackChunkName: "notfound" */ './views/NotFound.vue')
        }
    ]
});
