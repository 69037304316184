<template>
    <sign-up-v0-layout-snippet></sign-up-v0-layout-snippet>
</template>

<script>
import AccountAccessSharedMixin from '../Mixin/AccountAccessSharedMixin';
import SignUpV0LayoutSnippet from './Snippets/SignUpV0Layout';

export default {
    name: 'SignUpV0View',
    components: {
        SignUpV0LayoutSnippet
    },
    mixins: [
        AccountAccessSharedMixin
    ],
    beforeMount () {
        // If user is authenticated, go to 'new tenant account'.
        // Else, If user is not authenticated, got to 'new user'.
        // As you can see, we use replace, not push.
        if (this.accountIsAuthenticated === true) {
            this.$router.replace({ name: 'sign-up-v0-new-account' });
        } else {
            this.$router.replace({ name: 'sign-up-v0-new-user' });
        }
    }
};
</script>
