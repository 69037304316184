<template>
    <div class="common-logo-v1">
        <!-- LOGO -->
        <div class="text-center my-5">
            <logo :width="'164px'" :color="'#46b881'"></logo>
        </div>
    </div>
</template>

<script>
/**
 * CommonBoxLogoV1
 *
 * This component is registered globally in common/init/Components.init.js
 */
export default {
    name: 'CommonBoxLogoV1'
};
</script>
