<template>
    <div class="login-view ViewWrapper">
        <clv-head-meta :title="$t('Login')"></clv-head-meta>
        <div class="container">
            <div class="row">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">

                    <common-box-logo></common-box-logo>

                    <!-- BODY -->
                    <div class="gk-card gk-card--core-style-1 mb-5">

                        <!-- LOADER 2020-02-04 -->
                        <element-loading :active="loading || success" :background-color="'rgba(255, 255, 255, 0.9)'"></element-loading>

                        <!-- TITLE -->
                        <div class="mb-4">
                            <p class="mb-0 text-center weight-3 size-4">{{ $t('Login') }}</p>
                        </div>

                        <!-- SIGN UP LINK -->
                        <div class="mb-4">
                            <p class="text-muted text-center small mb-0">{{ $t('Don\'t have an account?') }} <router-link tag="a" :to="{ name: 'sign-up' }">{{ $t('Sign Up') }}</router-link></p>
                        </div>

                        <!-- ERROR -->
                        <div class="mb-4" v-if="error">
                            <div class="alert alert-danger C-Alert" role="alert">
                                <strong>{{ $t('Login failed') }}</strong>
                            </div>
                        </div>

                        <!-- CARD FORM -->
                        <form class="cltlr-form-label-style-1 position-relative" v-on:submit.prevent="submit">

                            <div class="form-group">
                                <label for="inputEmail">{{ $t('Email or Username') }}</label>
                                <input type="text" id="inputEmail" class="form-control" placeholder="" required autofocus v-model="username">
                            </div>
                            <div class="form-group">
                                <label for="inputPassword">{{ $t('Password') }}</label>
                                <input type="password" id="inputPassword" class="form-control" placeholder="" required v-model="password">
                            </div>
                            <div class="form-group">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="defaultCheck1" v-model="rememberMe">
                                    <label class="form-check-label" for="defaultCheck1">{{ $t('Remember me') }}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <button type="button" class="btn btn-accent1 btn-block" @click="submit" :disabled="disableSubmit">{{ $t('Log In') }}</button>
                                <router-link tag="a" :to="{ name: 'password-reset-init' }" class="small mb-0">{{ $t('Forgot Password?') }}</router-link>
                            </div>
                        </form>
                    </div>

                    <common-box-footer></common-box-footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import get from 'lodash/get';
import {
    LOCAL_STORAGE_JWT_KEY_DEPRECATED
} from '../common/init/ApiService.init';
import {
    CLOUTLAYER_WEBAPP_BASE_URL,
    CLOUTLAYER_WEBAPP_CORE_SIGN_UP_URL,
    CLOUTLAYER_WEBAPP_CORE_TENANT_ACCOUNTS_URL
} from '../common/config/config';
import { AccountService } from '../common/services/api.service';
import { AccountLogic } from '../common/logic/account.logic';
import AccountAccessSharedMixin from './Mixin/AccountAccessSharedMixin';

export default {
    name: 'LoginView',
    mixins: [
        AccountAccessSharedMixin
    ],
    data () {
        return {
            loading: false,
            success: false,
            error: null,
            redirect: null,
            // Data.
            username: '',
            password: '',
            rememberMe: false,
            // Form helpers
            usernameInvalidMessage: null,
            passwordInvalidMessage: null
        };
    },
    computed: {
        disableSubmit () {
            let username = this.username.trim();
            let password = this.password.trim();
            if (username === '' || password === '') {
                return true;
            }
            return false;
        }
    },
    beforeMount () {
        // If is authenticated, go to 'profile'.
        this.goToRouteBasedOnAuth(true, 'profile');

        // Get redirect (if exists).
        if (this.$route.query.hasOwnProperty('redirect')) {
            this.redirect = this.$route.query.redirect;
        }
    },
    methods: {
        submit () {
            let username = this.username.trim();
            let password = this.password.trim();
            let rememberMe = false;
            if (this.rememberMe) {
                rememberMe = true;
            }

            // Reset invalid messages.
            this.error = null;
            this.usernameInvalidMessage = null;
            this.passwordInvalidMessage = null;

            if (username === '') {
                this.usernameInvalidMessage = 'Username is required';
            }
            if (password === '') {
                this.passwordInvalidMessage = 'Password is required';
            }

            // Check if username or password invalid.
            if (this.usernameInvalidMessage || this.passwordInvalidMessage) {
                return;
            }

            // @future : More validation. Min/Max chars, username exists, DDoS, tries limit, block ip, ReCaptcha, etc.

            // Start loader.
            this.loading = true;
            AccountService.login(username, password, rememberMe).then((data) => {
                // To show the redirect loader.
                this.success = true;

                // Stop loader.
                this.loading = false;

                const authorization = get(data, 'headers.authorization', null);
                if (typeof authorization === 'string') {
                    const jwt = authorization.replace('Bearer ', '');
                    localStorage.setItem(LOCAL_STORAGE_JWT_KEY_DEPRECATED, jwt);
                }

                // If redirect param exists, redirect to that url.
                if (this.redirect) {
                    window.location.replace(this.redirect);
                } else {
                    AccountService.getAccount().then(({ data }) => {
                        // Get tenantIdList, length and first item (if exists).
                        const tenantIdList = AccountLogic.extractTenantIdList(data);
                        const tenantIdListLength = tenantIdList.length;
                        let firstTenantId = null;
                        if (tenantIdListLength >= 1) {
                            firstTenantId = tenantIdList[0];
                        }

                        // Check if user has only one account and redirect.
                        if (tenantIdListLength === 0) {
                            // Redirect to Sign Up and let it route the user flow.
                            // Expected behavior.
                            // Login -> Sign Up -> Sign Up V0 -> Sign Up V0 New Account.
                            window.location.replace(CLOUTLAYER_WEBAPP_CORE_SIGN_UP_URL);
                        } else if (tenantIdListLength === 1) {
                            if (firstTenantId) {
                                // Use redirect not Vue.js push.
                                window.location.replace(CLOUTLAYER_WEBAPP_BASE_URL + '/' + firstTenantId);
                            } else {
                                // Just in case.
                                console.warn('User has only one account but its invalid');
                                // Something went wrong. Go to accounts view. User should select his account.
                                // Use redirect not Vue.js push.
                                window.location.replace(CLOUTLAYER_WEBAPP_CORE_TENANT_ACCOUNTS_URL);
                            }
                        } else {
                            // Go to accounts view. Go to accounts view. User should select his account.
                            // Use redirect not Vue.js push.
                            window.location.replace(CLOUTLAYER_WEBAPP_CORE_TENANT_ACCOUNTS_URL);
                        }
                    }).catch((reason) => {
                        // Something went wrong. Go to accounts view. User should select his account.
                        // Use redirect not Vue.js push.
                        window.location.replace(CLOUTLAYER_WEBAPP_CORE_TENANT_ACCOUNTS_URL);
                    });
                }
                // window.location.replace('/');
            }).catch((err) => {
                this.error = err;
                // Stop loader.
                this.loading = false;
            });
        }
    }
};
</script>
