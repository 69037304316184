import _ from 'lodash';

import { AccountService } from '../../common/services/api.service';

// Actions [TYPES].
export const ACTION_GET_USER = 'getUser';

// Mutations.
export const START_USER_FETCHING = 'startUserFetching';
export const STOP_USER_FETCHING = 'stopUserFetching';

export const SET_USER = 'setUser';
export const RESET_USER = 'resetUser';

export const SET_USER_ERROR_GET = 'setUserErrorGet';
export const RESET_USER_ERROR_GET = 'resetUserErrorGet';

// State.
const state = {
    // User //////////
    userFetching: false,
    user: null,
    userErrorGet: null
};

// Getters.
const getters = {
    isAuthenticated (state) {
        return !!state.user;
    },

    // Persistence //////////
    userFetching (state) {
        return state.userFetching;
    },
    user (state) {
        return state.user;
    },
    userErrorGet (state) {
        return state.userErrorGet;
    }
};

// Actions.
const actions = {
    [ACTION_GET_USER] ({ commit }) {
        commit(START_USER_FETCHING);
        return AccountService.getAccount()
            .then(({ data }) => {
                // Set new data and reset error.
                commit(SET_USER, data);
                commit(RESET_USER_ERROR_GET);
                return Promise.resolve(data);
            })
            .catch((error) => {
                commit(RESET_USER);
                commit(SET_USER_ERROR_GET, error);
                return Promise.reject(error);
            })
            .finally(() => {
                commit(STOP_USER_FETCHING);
            });
    }
};

// Mutations.
const mutations = {
    [START_USER_FETCHING] (state) {
        state.userFetching = true;
    },
    [STOP_USER_FETCHING] (state) {
        state.userFetching = false;
    },

    [SET_USER] (state, data) {
        state.user = _.cloneDeep(data);
    },
    [RESET_USER] (state) {
        state.user = null;
    },

    [SET_USER_ERROR_GET] (state, data) {
        state.userErrorGet = _.cloneDeep(data);
    },
    [RESET_USER_ERROR_GET] (state) {
        state.userErrorGet = null;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
