import Vue from 'vue';
import _ from 'lodash';
import UrlService from './url.service';

const defaultConfiguration = {
    withCredentials: true,
    crossDomain: true
};

const ApiService = {
    setHeader () {},
    head (url, config = _.cloneDeep(defaultConfiguration)) {
        return Vue.axios.head(url, config);
    },
    get (url, config = _.cloneDeep(defaultConfiguration)) {
        return Vue.axios.get(url, config);
    },
    delete (url, config = _.cloneDeep(defaultConfiguration)) {
        return Vue.axios.delete(url, config);
    },
    post (url, data = null, config = _.cloneDeep(defaultConfiguration)) {
        return Vue.axios.post(url, data, config);
    },
    put (url, data = null, config = _.cloneDeep(defaultConfiguration)) {
        return Vue.axios.put(url, data, config);
    }
};

export default ApiService;

export const AccountService = {
    login (username, password, rememberMe = false) {
        // let url = UrlService.urlForGatewayHestiaApi('auth/login');
        let url = UrlService.urlForKernelVersionedApi('auth/login');
        return ApiService.post(url, {
            login: username,
            password: password,
            rememberMe: rememberMe
        });
    },
    logout () {
        let url = UrlService.urlForKernelVersionedApi('auth/logout');
        return ApiService.post(url);
    },
    reload () {
        let url = UrlService.urlForKernelVersionedApi('auth/reload');
        return ApiService.post(url);
    },
    getAccount () {
        let path = 'user-accounts/whoami';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    },
    whoAmI () {
        let path = 'auth/whoami';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    },
    saveAccount (account) {
        let url = UrlService.urlForKernelVersionedApi('user-accounts');
        return ApiService.post(url, account);
    },
    activateAccount (key) {
        let path = 'user-accounts/activations/completions?key=' + key;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    },
    changePassword (passwordChangeDto) {
        let url = UrlService.urlForKernelVersionedApi('user-accounts/password-changes');
        return ApiService.post(url, passwordChangeDto);
    },
    requestPasswordReset (email) {
        let url = UrlService.urlForKernelVersionedApi('user-accounts/password-resets/initializations');
        const config = _.cloneDeep(defaultConfiguration);
        config['headers'] = {
            'Content-Type': 'text/plain'
        };
        return ApiService.post(url, email, config);
    },
    finishPasswordReset (keyAndPassword) {
        let url = UrlService.urlForKernelVersionedApi('user-accounts/password-resets/completions');
        return ApiService.post(url, keyAndPassword);
    },

    // @future : Remove the methods below after alpha version.
    registerAccountAlphaWithInvitationCode (account, invitationCode) {
        const path = 'user-accounts/registrations?invitationCode=' + invitationCode;
        const url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.post(url, account);
    },
    checkInvitationCode (code) {
        let path = 'public/invitation-codes/checks?code=' + code;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    }
};

export const TenantAccountService = {
    /**
     * Retrieves all accounts that current user has access to
     *
     * @returns the list of entities
     */
    getUserTenantAccounts () {
        let path = 'tenant-accounts';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    },
    createTenantAccount (dto) {
        let path = 'tenant-accounts/registrations';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.post(url, dto);
    }
};
