<template>
    <div class="common-box-links-v1">

        <!-- MENU -->
        <p class="text-muted text-center small mb-0" v-if="accountIsAuthenticated">
            <router-link tag="a" :to="{ name: 'profile' }" class="mr-2">{{ $t('Profile') }}</router-link>
            <router-link tag="a" :to="{ name: 'logout' }">{{ $t('Log Out') }}</router-link>
        </p>
        <p class="text-muted text-center small mb-0" v-else>
            <router-link tag="a" :to="{ name: 'login' }" class="mr-2">{{ $t('Login') }}</router-link>
            <router-link tag="a" :to="{ name: 'sign-up' }">{{ $t('Sign Up') }}</router-link>
        </p>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * CommonBoxLinksV1
 *
 * This component is registered globally in common/init/Components.init.js
 */
export default {
    name: 'CommonBoxLinksV1',
    computed: {
        ...mapGetters('account', {
            accountIsAuthenticated: 'isAuthenticated'
        })
    }
};
</script>
