<template>
    <div id="app">
        <core-loader v-if="applicationInitializing" :with-text="false" :with-padding="true"></core-loader>
        <div id="app-layout" v-if="!applicationInitializing">
            <main id="app-main-wrapper">
                <router-view/>
            </main>
        </div>
    </div>
</template>

<script>
import localStorageStore from 'store';
import i18n from './common/plugins/i18n';

/**
 * App - This component is responsible to get all the necessary information from system.
 * AppLayout - This component is responsible to present the information that previously the App component fetched from system.
 *
 * @future : @see : Tasks:
 * - The quote of the day (Only those who dare to fail greatly can ever achieve greatly. JFK)
 * - Latest news
 * - Notifications (status, ToS, etc)
 * - ToS, Privacy, Status etc links in every page.
 * - Copyrights in every page.
 * - Forgot password
 * - Data in local storage for personalization and other mechanisms.
 */
export default {
    name: 'App',
    components: {},
    data () {
        return {
            // Flags //////////
            applicationInitializing: true
        };
    },
    computed: {
        appLocale () {
            return i18n.locale;
        }
    },
    watch: {
        'appLocale' (value) {
            // Set last accessed lang key.
            localStorageStore.set('lastAccessedLangKey', value);
        }
    },
    created () {
        // Set appInitializing flag to true. (Application's state : initializing).
        this.applicationInitializing = true;
    },
    mounted () {
        // Initialize Application.
        this.initializeApplication();
    },
    methods: {
        /**
         * This method check if user is authenticated (by trying to get the obj from UAA).
         * If user is authenticated, it initializes the app with language
         * and tenant accounts that this user has membership to.
         */
        async initializeApplication () {
            // Get langKey from url.
            let langKey = this.$route.query.langKey;

            // If lang key from url exists, change app locale.
            if (langKey) {
                this.changeAppLocale(langKey);

                // Remove langKey from url.
                let query = this.$route.query;
                delete query.langKey;
                this.$router.replace({ query: query });

                // Set last accessed lang key.
                localStorageStore.set('lastAccessedLangKey', langKey);
            } else {
                // Check if local storage has lastAccessedKey and set.
                let lastAccessedLangKey = localStorageStore.get('lastAccessedLangKey');

                // If exists, set.
                if (lastAccessedLangKey) {
                    this.changeAppLocale(lastAccessedLangKey);
                } else {
                    // @future : Get language from IP - https://ip2c.org/s - https://ip2c.org
                }
            }

            // @help : We do not set last accessed langKey. [De exei kapoia logiki pros to paron]

            // Check if account is already authenticated.
            const userResult = await this.$store.dispatch('account/getUser').then((account) => {
                return account;
            }).catch((reason) => {
                return null;
            });

            // If userResult is null, end process and set applicationInitializing to false.
            if (userResult === null) {
                // Set appInitializing flag to false. (Application's state : initialized).
                this.applicationInitializing = false;

                return;
            }

            // User's lang key has higher priority than url lang key or store last accessed lang key.
            this.changeAppLocale(userResult.langKey);

            // Fetch TenantAccounts that this user has membership to.
            await this.$store.dispatch('account/getTenantAccountList').then((tenantAccountList) => {
                return tenantAccountList;
            }).catch((reason) => {
                return null;
            });

            // Set appInitializing flag to false. (Application's state : initialized).
            this.applicationInitializing = false;
        }
    }
};
</script>
