<template>
    <sign-up-v0-layout-snippet>
        <clv-head-meta :title="$t('Get started with Cloutlayer')"></clv-head-meta>
        <div class="sign-up-v0-new-user-view position-relative">

            <invitation-code-modal-and-form-snippet :key="invitationCodeV0ModalKey"
                                                    :with-information="invitationCodeV0ModalWithInformation"
                                                    v-on:check-success="setInvitationCodeV0Code"></invitation-code-modal-and-form-snippet>

            <!-- LOADER 2020-02-04 -->
            <element-loading :active="loading" background-color="rgba(255, 255, 255, 0.9)"></element-loading>

            <div class="mt-5"></div>

            <!-- LANGUAGE -->
            <div class="row mb-2">
                <div v-bind:class="bootstrapGridColClass" class="text-center">
                    <p class="small text-muted mb-0 user-select-none">
                        <span class="cursor-pointer" @click="changeAppLocale('el')">EL</span> / <span class="cursor-pointer" @click="changeAppLocale('en')">EN</span>
                    </p>
                </div>
            </div>

            <!-- LOGIN -->
            <div class="row mb-5">
                <div v-bind:class="bootstrapGridColClass" class="text-center">
                    <p class="small mb-0">
                        {{ $t('Have an account?' ) }} <router-link tag="a" :to="{ name: 'login' }">{{ $t('Login' ) }}</router-link>
                    </p>
                </div>
            </div>

            <!-- SIGN UP STEPS -->
            <div class="row mb-5">
                <div v-bind:class="bootstrapGridColClass" class="text-center">
                    <span class="text-muted small weight-4">{{ $t('Step') }} {{ activeStep }} {{ $t('of') }} 3</span>
                </div>
            </div>

            <!-- EMAIL VERIFICATION STEP (AFTER FORM SUCCESS) -->
            <div class="row mb-5" v-if="displayFormSuccessSection">
                <div v-bind:class="bootstrapGridColClass">
                    <div class="text-center">
                        <img class="mb-4" src="../../assets/illustrations/mail-sent-01.svg" alt="Mail Sent Illustration" style="width: 140px;"/>
                        <p class="weight-4 size-5 mb-4">{{ $t('Email_Confirmation_Link') }}</p>
                        <div class="mb-4" v-if="emailProvider !== null">
                            <a class="btn btn-sm btn-secondary-alt cursor-pointer" :href="emailProvider.url">{{ $t(emailProvider.label) }}</a>
                        </div>
                        <p class="text-microcopy--70 mb-4">{{ $t('Email_Confirmation_Link_SPAM_Note') }}</p>
                        <p class="text-microcopy--65 mb-0">{{ $t('Your information is safe and secure in Cloutlayer') }}</p>
                    </div>
                </div>
            </div>

            <!-- FORM ERROR -->
            <div class="row mb-3" v-if="displayFormRelatedSections && createError !== null">
                <div v-bind:class="bootstrapGridColClass">
                    <div class="alert alert-danger C-Alert mb-0" role="alert">
                        <p class="mb-0" v-if="emailExists">{{ $t('Registration_email_exists' ) }}</p>
                        <p class="mb-0" v-else-if="usernameExists">{{ $t('Registration_username_exists' ) }}</p>
                        <p class="mb-0" v-else-if="invitationCodeV0Invalid">
                            {{ $t('Registration_invitation_code_v0_invalid' ) }}
                            <span class="ml-2 text-microcopy--70 text-primary font-weight-bold cursor-pointer" @click="invalidInvitationCodeV0CallToAction">{{ $t('Registration_invitation_code_v0_invalid_use_another') }}</span>
                        </p>
                        <p class="mb-0" v-else>{{ $t('Registration_failed' ) }}</p>
                    </div>
                </div>
            </div>

            <!-- FORM -->
            <div class="row mb-5" v-if="displayFormRelatedSections">
                <div v-bind:class="bootstrapGridColClass">
                    <new-user-form-v0-snippet :provided-form-fields="formFields"
                                              v-on:submit="submitNewUserFormSnippetHandler"></new-user-form-v0-snippet>
                </div>
            </div>

            <!-- MICROCOPIES -->
            <div class="row mb-5" v-if="displayFormRelatedSections">
                <div v-bind:class="bootstrapGridColClass" class="text-center">
                    <p class="text-microcopy--80"><span class="d-block">{{ $t('sign_up_v0_tos_accept_1_pre' ) }}</span> <a :href="$t('TermsOfService_URL')">{{ $t('sign_up_v0_tos_accept_1_post' ) }}</a></p>
                    <hr/>
                    <p class="text-microcopy--70">{{ $t('sign_up_v0_privacy_statement_pre' ) }} <a :href="$t('PrivacyPolicy_URL')">{{ $t('sign_up_v0_privacy_statement_post' ) }}</a></p>
                </div>
            </div>

        </div>
    </sign-up-v0-layout-snippet>
</template>

<script>
import { AccountService } from '../../common/services/api.service';
import AccountAccessSharedMixin from '../Mixin/AccountAccessSharedMixin';
import NewUserFormV0Snippet from './Snippets/NewUserFormV0';
import SignUpV0LayoutSnippet from './Snippets/SignUpV0Layout';
import InvitationCodeModalAndFormSnippet from './Snippets/InvitationCodeModalAndForm';

export default {
    name: 'SignUpV0NewUserView',
    components: {
        InvitationCodeModalAndFormSnippet,
        SignUpV0LayoutSnippet,
        NewUserFormV0Snippet
    },
    mixins: [
        AccountAccessSharedMixin
    ],
    data () {
        return {
            // UI //////////
            bootstrapGridColClass: 'col-sm-12 offset-sm-0 col-md-8 offset-md-2',

            // InvitationCodeV0 Modal //////////
            invitationCodeV0ModalKey: 1,
            invitationCodeV0ModalWithInformation: true,

            // Persistence / Async //////////
            loading: false,
            createError: null,
            usernameExists: false,
            emailExists: false,
            invitationCodeV0Invalid: false,
            createSuccess: false,

            // Form //////////
            activeStep: 1,
            formFields: {
                username: '',
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                password: '',
                passwordRepeat: '',
                langKey: 'en',

                invitationCodeV0Code: '' // It will be added as param to URL.
            }
        };
    },
    computed: {
        displayFormRelatedSections () {
            return this.createSuccess === false;
        },
        displayFormSuccessSection () {
            return this.createSuccess === true && this.loading === false;
        },
        emailProvider () {
            if (!this.formFields.email) {
                return null;
            }
            if (this.formFields.email.includes('gmail.')) {
                return {
                    'provider': 'gmail',
                    'label': 'Open Gmail',
                    'url': 'https://mail.google.com'
                };
            } else if (this.formFields.email.includes('outlook.')) {
                return {
                    'provider': 'outlook',
                    'label': 'Open Outlook',
                    'url': 'https://outlook.live.com/owa/'
                };
            } else if (this.formFields.email.includes('hotmail.')) {
                return {
                    'provider': 'outlook',
                    'label': 'Open Outlook',
                    'url': 'https://outlook.live.com/owa/'
                };
            } else if (this.formFields.email.includes('icloud.')) {
                return {
                    'provider': 'icloud',
                    'label': 'Open iCloud',
                    'url': 'https://www.icloud.com/'
                };
            } else if (this.formFields.email.includes('yahoo.')) {
                return {
                    'provider': 'yahoo',
                    'label': 'Open Yahoo',
                    'url': 'https://mail.yahoo.com'
                };
            } else {
                return null;
            }
        }
    },
    beforeMount () {
        // If user IS authenticated, go to 'sign-up' and let it re-route the UX flow.
        this.goToRouteBasedOnAuth(true, 'sign-up', true); // Use replace.
    },
    methods: {
        setInvitationCodeV0Code (value) {
            // Set new value from child component.
            this.formFields.invitationCodeV0Code = value;

            // Set error to false.
            // To kanoume auto gia na min mperdeuoume ton xristi.
            // @future : important : make sure that this assignment does not cause UX error or loss of explanatory info.
            // Pros to paron opos exei domithei eimaste okay. Alla thelei prosoxi an allaxeis kati sta errors.
            this.createError = null;
            this.invitationCodeV0Invalid = false;
        },
        invalidInvitationCodeV0CallToAction () {
            // Possible actions:

            // Reload page in order to display modal again : reloadCurrentPage
            // if (typeof location !== 'undefined') {
            //     location.reload();
            // }

            // Hide information after the 1st open.
            this.invitationCodeV0ModalWithInformation = false;

            // Increase :key of modal component in order to be re-mounted.
            this.invitationCodeV0ModalKey = this.invitationCodeV0ModalKey + 10;
        },
        submitNewUserFormSnippetHandler (data) {
            // Check if form is valid.
            if (!data.valid) {
                return;
            }

            // Map data.
            this.formFields.username = data.formFields.username;
            this.formFields.firstName = data.formFields.firstName;
            this.formFields.lastName = data.formFields.lastName;
            this.formFields.email = data.formFields.email;
            this.formFields.phoneNumber = data.formFields.phoneNumber;
            this.formFields.password = data.formFields.password;
            this.formFields.passwordRepeat = data.formFields.passwordRepeat;
            // this.formFields.langKey = data.formFields.langKey;
            // Force get langKey from app.
            this.formFields.langKey = this.getAppLocale();

            // Perform async operation to create new user.
            this.createNewUser();
        },

        // CREATE PROCESS //////////
        createNewUser () {
            // @future : Extra Validation [IMPORTANT].

            // Start loader.
            this.loading = true;

            // Reset error.
            this.createError = null;

            // Reset flags.
            this.emailExists = false;
            this.usernameExists = false;
            this.invitationCodeV0Invalid = false;

            // Prepare DTO.
            const dto = {
                id: null,
                login: this.formFields.username,
                firstName: this.formFields.firstName,
                lastName: this.formFields.lastName,
                email: this.formFields.email,
                // imageUrl: null,
                // activated: false,
                langKey: this.formFields.langKey,
                // createdBy: '',
                // createdDate: null,
                // lastModifiedBy: '',
                // lastModifiedDate: null,
                // authorities: null
                password: this.formFields.password
                // phoneNumber: this.formFields.phoneNumber // It is not used yet.
            };

            // Make request to Zeus to sign up new account.
            // AccountService.registerAccountDefault(dto).then(({ data }) => {
            AccountService.registerAccountAlphaWithInvitationCode(dto, this.formFields.invitationCodeV0Code)
                // use { data } to get access in response payload.
                .then(() => {
                    this.activeStep = 2; // Increase step. This field is explanatory.
                    this.createSuccess = true;
                })
                .catch((reason) => {
                    // Set error.
                    this.createError = reason;
                    if (reason.dataMessage === 'error.userexists') {
                        this.usernameExists = true;
                    } else if (reason.dataMessage === 'error.emailexists') {
                        this.emailExists = true;
                    } else if (reason.dataMessage === 'error.invitationCodeInvalid' ||
                        reason.dataMessage === 'error.invitationCodeNull') {
                        this.invitationCodeV0Invalid = true;
                    }
                })
                .finally(() => {
                    // Stop Loader.
                    this.loading = false;
                });
        }
    }
};
</script>
