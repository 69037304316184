<template>
    <div class="new-user-form-v0-snippet">
        <form class="cltlr-form-label-style-1" v-on:submit.prevent="submit" data-vv-scope="newUserFormV0">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="firstName">{{ $t('First Name') }}</label>
                    <input type="text" class="form-control" name="vv_firstName" id="firstName" autofocus v-validate="'required|min:2|max:50'" v-model="formFields.firstName">
                    <span class="small text-danger">{{ errors.first('newUserFormV0.vv_firstName') }}</span>
                </div>
                <div class="form-group col-md-6">
                    <label for="lastName">{{ $t('Last Name') }}</label>
                    <input type="text" class="form-control" name="vv_lastName" id="lastName" v-validate="'required|min:2|max:50'" v-model="formFields.lastName">
                    <span class="small text-danger">{{ errors.first('newUserFormV0.vv_lastName') }}</span>
                </div>
            </div>
            <div class="form-group">
                <label for="email">{{ $t('Email') }}</label>
                <input type="email" class="form-control" id="email" name="vv_email" v-validate="'email|required|max:254'" v-model="formFields.email">
                <span class="small text-danger">{{ errors.first('newUserFormV0.vv_email') }}</span>
            </div>
            <div class="form-group">
                <label for="username">{{ $t('Username') }}</label>
                <input type="text" class="form-control" id="username" name="vv_username" v-validate="'alpha|required|min:4|max:50'" v-model="formFields.username">
                <span class="small text-danger">{{ errors.first('newUserFormV0.vv_username') }}</span>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="password">{{ $t('Password') }}</label>
                    <input type="password" class="form-control" id="password" name="vv_password" ref="vv_password" v-validate="'required|min:8|max:64|password_strength'" v-model="formFields.password">
                    <span class="small text-danger" v-show="errors.has('newUserFormV0.vv_password')">{{ $t('Password_Validation_1') }}</span>
                </div>
                <div class="form-group col-md-6">
                    <label for="passwordRepeat">{{ $t('Repeat Password') }}</label>
                    <input type="password" class="form-control" id="passwordRepeat" name="vv_passwordRepeat" v-validate="'required|confirmed:vv_password'" v-model="formFields.passwordRepeat" data-vv-as="vv_passwordRepeat">
                    <span class="small text-danger" v-show="errors.has('newUserFormV0.vv_passwordRepeat')">{{ $t('Password_Validation_No_Match_1') }}</span>
                </div>
            </div>
            <!--
            <div class="form-group">
                <label>{{ $t('Language') }}</label>
                <select class="form-control" name="vv_language" v-model="formFields.langKey">
                    <option value="en" selected>English</option>
                    <option value="el">Greek (Ελληνικά)</option>
                </select>
            </div>
            -->
            <!--
            <div class="form-group">
                <label for="phoneNumber">{{ $t('Phone Number') }}</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">+</span>
                    </div>
                    <input type="text" class="form-control" id="phoneNumber" name="vv_phoneNumber" v-validate="'required|numeric'" v-model="formFields.phoneNumber">
                </div>
                <span class="small text-danger">{{ errors.first('newUserFormV0.vv_phoneNumber') }}</span>
            </div>
            -->
            <div class="form-group mt-2">
                <button type="button" class="btn btn-block btn-accent1" @click="submit">{{ $t('Okay, next') }}</button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: 'NewUserFormV0Snippet',
    props: {
        providedFormFields: {
            type: Object,
            default: null,
            required: false
        }
    },
    components: {},
    data () {
        return {
            formFields: {
                username: '',
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                passwordRepeat: '',
                phoneNumber: '',
                langKey: 'en' // Let the parent component set it.
            }
        };
    },
    beforeMount () {
        // @future : Make some validations (email, password, phone, langKey, etc)... (?)
        this.formFields.username = this.providedFormFields.username;
        this.formFields.firstName = this.providedFormFields.firstName;
        this.formFields.lastName = this.providedFormFields.lastName;
        this.formFields.email = this.providedFormFields.email;
        this.formFields.password = this.providedFormFields.password;
        this.formFields.passwordRepeat = this.providedFormFields.passwordRepeat;
        this.formFields.phoneNumber = this.providedFormFields.phoneNumber;
        if (this.providedFormFields.langKey === null ||
            this.providedFormFields.langKey === '' ||
            (this.providedFormFields.langKey !== 'en' && this.providedFormFields.langKey !== 'el')) {
            this.formFields.langKey = this.getAppLocale(); // get from app (@future : enable select box?).
        } else {
            this.formFields.langKey = this.providedFormFields.langKey;
        }
    },
    methods: {
        submit () {
            this.$validator.validateAll('newUserFormV0').then(valid => {
                if (valid) {
                    this.$emit('submit', {
                        valid: true,
                        formFields: this.formFields
                    });
                } else {
                    this.$emit('submit', {
                        valid: false,
                        formFields: this.formFields
                    });
                }
            });
        }
    }
};
</script>
