import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import get from 'lodash/get';
import AxiosErrorTransformer from 'vuetiful/src/transformers/axios-error.transformer';

export const LOCAL_STORAGE_JWT_KEY_DEPRECATED = 'auth_dprctd';

const AppApiService = {
    init () {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.withCredentials = true;

        // eslint-disable-next-line
        const reqOnFulfilled = (config) => {
            const jwt = localStorage.getItem(LOCAL_STORAGE_JWT_KEY_DEPRECATED);
            if (typeof jwt === 'string') {
                config.headers['Authorization'] = 'Bearer ' + jwt;
            }
            return Promise.resolve(config);
        };
        Vue.axios.interceptors.request.use(null, null);

        // Intercept successful responses and ensure the authentication token to local storage.
        // eslint-disable-next-line
        const responseOnFulfilled = (value) => {
            const authorization = get(value, 'headers.authorization', null);
            if (typeof authorization === 'string') {
                const jwt = authorization.replace('Bearer ', '');
                localStorage.setItem(LOCAL_STORAGE_JWT_KEY_DEPRECATED, jwt);
            }
            return Promise.resolve(value);
        };
        // Intercept failed responses and transform error response.
        const responseOnRejected = (error) => {
            // Transform error.
            error = AxiosErrorTransformer.flatten(error);
            error = AxiosErrorTransformer.setMessages(error);

            // eslint-disable-next-line
            return Promise.reject(error);
        };
        Vue.axios.interceptors.response.use(null, responseOnRejected);
    }
};

export default AppApiService;
