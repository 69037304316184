import Vue from 'vue';
import Vuex from 'vuex';

import account from './modules/account.module';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,
    modules: {
        account: account
    }
});
