import _ from 'lodash';
import { TenantAccountService } from '../../common/services/api.service';

// Actions [TYPES].
export const ACTION_GET_TENANT_ACCOUNT_LIST = 'getTenantAccountList';

// Mutations.
export const START_TENANT_ACCOUNT_LIST_FETCHING = 'startTenantAccountListFetching';
export const STOP_TENANT_ACCOUNT_LIST_FETCHING = 'stopTenantAccountListFetching';

export const SET_TENANT_ACCOUNT_LIST = 'setTenantAccountList';
export const RESET_TENANT_ACCOUNT_LIST = 'resetTenantAccountList';

export const SET_TENANT_ACCOUNT_LIST_ERROR_GET = 'setTenantAccountListErrorGet';
export const RESET_TENANT_ACCOUNT_LIST_ERROR_GET = 'resetTenantAccountListErrorGet';

/*
 * This submodule handles the changes between comfortable and advanced search type.
 * This submodule implements only the 'toggle' mutation.
 * It calls mutations from root module or from other submodules.
 */

// State.
const state = {
    tenantAccountListFetching: false,
    tenantAccountList: [],
    tenantAccountListErrorGet: null
};

// Getters.
const getters = {
    tenantAccountListFetching (state) {
        return state.tenantAccountListFetching;
    },
    tenantAccountList (state) {
        return state.tenantAccountList;
    },
    tenantAccountListLength (state) {
        return state.tenantAccountList.length;
    },
    tenantAccountListErrorGet (state) {
        return state.tenantAccountListErrorGet;
    }
};

// Actions.
const actions = {
    [ACTION_GET_TENANT_ACCOUNT_LIST] ({ dispatch, commit, state }) {
        commit(START_TENANT_ACCOUNT_LIST_FETCHING);
        return TenantAccountService.getUserTenantAccounts()
            .then(({ data }) => {
                // Set new data and reset error.
                commit(SET_TENANT_ACCOUNT_LIST, data);
                commit(RESET_TENANT_ACCOUNT_LIST_ERROR_GET);
                return Promise.resolve(data);
            })
            .catch((error) => {
                commit(RESET_TENANT_ACCOUNT_LIST);
                commit(SET_TENANT_ACCOUNT_LIST_ERROR_GET, error);
                return Promise.reject(error);
            })
            .finally(() => {
                commit(STOP_TENANT_ACCOUNT_LIST_FETCHING);
            });
    }
};

// Mutations.
const mutations = {
    [START_TENANT_ACCOUNT_LIST_FETCHING] (state) {
        state.tenantAccountListFetching = true;
    },
    [STOP_TENANT_ACCOUNT_LIST_FETCHING] (state) {
        state.tenantAccountListFetching = false;
    },

    [SET_TENANT_ACCOUNT_LIST] (state, data) {
        state.tenantAccountList = _.cloneDeep(data);
    },
    [RESET_TENANT_ACCOUNT_LIST] (state) {
        state.tenantAccountList = [];
    },

    [SET_TENANT_ACCOUNT_LIST_ERROR_GET] (state, data) {
        state.tenantAccountListErrorGet = _.cloneDeep(data);
    },
    [RESET_TENANT_ACCOUNT_LIST_ERROR_GET] (state) {
        state.tenantAccountListErrorGet = null;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
