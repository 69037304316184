<template>
    <sign-up-v0-layout-snippet>
        <clv-head-meta :title="$t('Your foundation for communication, influence, growth')"></clv-head-meta>
        <div class="sign-up-v0-new-account-view position-relative">

            <!-- LOADER 2020-02-04 -->
            <element-loading :active="loading" background-color="rgba(255, 255, 255, 0.9)"></element-loading>

            <div class="mt-5"></div>

            <!-- LANGUAGE -->
            <!-- At this point language cannot be change. User can change language by updating her profile in 'profile' view. -->

            <!-- LOGIN -->
            <div class="row mb-5" v-if="displayFormRelatedSections">
                <div v-bind:class="bootstrapGridColClass" class="text-center">
                    <p class="small text-muted mb-0 user-select-none">
                        <router-link tag="a" :to="{ name: 'profile' }">{{ $t('Profile' ) }}</router-link> / <router-link tag="a" :to="{ name: 'accounts' }">{{ $t('Accounts' ) }}</router-link>
                    </p>
                </div>
            </div>

            <!-- SIGN UP STEPS -->
            <!--
            @future: UX
            I logiki leei oti ta steps einai gia tous xristes pou ftiaxnoun proti fora account.
            Arra deixe to step mono an exei 0 accounts.
            ektos an valeis vima me pliromes ktl
            -->
            <div class="row mb-5" v-if="displayFormRelatedSections">
                <div v-bind:class="bootstrapGridColClass" class="text-center">
                    <span class="text-muted small weight-4">{{ $t('Step') }} {{ activeStep }} {{ $t('of') }} 3</span>
                </div>
            </div>

            <!-- FORM SUCCESS -->
            <div class="row mb-5" v-if="displayFormSuccessSection">
                <div v-bind:class="bootstrapGridColClass">
                    <div class="text-center">
                        <img class="mb-4" src="../../assets/illustrations/to-the-moon-01.svg" style="width: 240px;"/>
                        <p class="weight-5 size-3 mb-0">{{ $t('Welcome to Cloutlayer') }}</p>
                        <a :href="newTenantAccountAppUrl" class="text-microcopy--70 mb-0 text-primary">{{ $t('Redirecting to app') }}</a>
                        <core-loader :with-padding="true"></core-loader>
                    </div>
                </div>
            </div>

            <!-- FORM ERROR -->
            <div class="row mb-3" v-if="displayFormRelatedSections && createError !== null">
                <div v-bind:class="bootstrapGridColClass">
                    <div class="alert alert-danger C-Alert mb-0" role="alert">
                        <p class="mb-0" v-if="aliasExists">{{ $t('Registration_account_alias_exists' ) }}</p>
                        <p class="mb-0" v-else>{{ $t('Registration_failed' ) }}</p>
                    </div>
                </div>
            </div>

            <!-- FORM -->
            <div class="row mb-5" v-if="displayFormRelatedSections">
                <div v-bind:class="bootstrapGridColClass">
                    <new-account-form-v0-snippet :provided-form-fields="formFields"
                                                 v-on:submit="submitNewAccountFormSnippetHandler"></new-account-form-v0-snippet>
                </div>
            </div>
        </div>
    </sign-up-v0-layout-snippet>
</template>

<script>
import { CLOUTLAYER_WEBAPP_URL } from '@/common/config/config';
import AccountAccessSharedMixin from '../Mixin/AccountAccessSharedMixin';
import SignUpV0LayoutSnippet from './Snippets/SignUpV0Layout';
import NewAccountFormV0Snippet from './Snippets/NewAccountFormV0';
import { AccountService, TenantAccountService } from '@/common/services/api.service';

export default {
    name: 'SignUpV0NewAccountView',
    components: {
        SignUpV0LayoutSnippet,
        NewAccountFormV0Snippet
    },
    mixins: [
        AccountAccessSharedMixin
    ],
    data () {
        return {
            // UI //////////
            bootstrapGridColClass: 'col-sm-12 offset-sm-0 col-md-8 offset-md-2',

            // Persistence / Async //////////
            loading: false,
            createError: null,
            aliasExists: false,
            createSuccess: false,
            newTenantAccountAppUrl: null,

            // Form //////////
            activeStep: 3,
            formOpenCustomization: false,
            formFields: {
                name: '',
                alias: '',
                defaultLangKey: 'EN', // Set the default. It will be changed.
                politicsAddOn: false,
                gdprAddOn: false,
                i18nAddOn: false
            }
        };
    },
    computed: {
        displayFormRelatedSections () {
            return this.createSuccess === false;
        },
        displayFormSuccessSection () {
            return this.createSuccess === true && this.loading === false;
        }
    },
    beforeMount () {
        // If user IS NOT authenticated, go to 'sign-up' and let it re-route the UX flow.
        this.goToRouteBasedOnAuth(false, 'sign-up', true); // Use replace.

        // Set defaultLangKey (only beforeMount)
        // User can change it later.
        this.formFields.defaultLangKey = this.getAppLocale().toUpperCase();
    },
    methods: {
        submitNewAccountFormSnippetHandler (data) {
            // Check if form is valid.
            if (!data.valid) {
                return;
            }

            // Map data.
            this.formFields.name = data.formFields.name;
            this.formFields.alias = data.formFields.alias;
            this.formFields.defaultLangKey = data.formFields.defaultLangKey;
            this.formFields.politicsAddOn = data.formFields.politicsAddOn;
            this.formFields.gdprAddOn = data.formFields.gdprAddOn;
            this.formFields.i18nAddOn = data.formFields.gdprAddOn;

            // Perform async operation to create new user.
            this.createNewAccount();
        },

        // CREATE PROCESS //////////
        async createNewAccount () {
            // @future : Return promise.
            // @future : Extra Validation [IMPORTANT].

            // Start loader.
            this.loading = true;

            // Reset error.
            this.createError = null;

            // Reset flags.
            this.aliasExists = false;

            // Prepare DTO.
            const dto = {
                id: null,
                name: this.formFields.name,
                alias: this.formFields.alias,
                defaultLangKey: this.formFields.defaultLangKey,
                flags: {
                    politicsAddOnEnabled: this.formFields.politicsAddOn,
                    gdprAddOnEnabled: this.formFields.gdprAddOn,
                    i18nAddOnEnabled: this.formFields.i18nAddOn
                },
                extra: {}
            };

            // Make request to Accounts to sign up new account.
            const tenantAccountResult = await TenantAccountService.createTenantAccount(dto).then(({ data }) => {
                return {
                    success: true,
                    data: data,
                    error: null
                };
            }).catch((reason) => {
                return {
                    success: false,
                    data: null,
                    error: reason
                };
            });

            // Check result.
            if (tenantAccountResult.success === false) {
                // Set error.
                this.createError = tenantAccountResult.error;

                // Check for specific errors.
                if (tenantAccountResult.error.hasOwnProperty('dataMessage')) {
                    if (tenantAccountResult.error.dataMessage === 'error.accountaliasexists') {
                        this.aliasExists = true;
                    }
                }

                // Stop loader.
                this.loading = false;

                return;
            }

            // this.activeStep = 3; // Increase step. This field is explanatory.
            this.createSuccess = true;

            // Set the new TenantAccount URL.
            this.newTenantAccountAppUrl = CLOUTLAYER_WEBAPP_URL + '/tenant/' + tenantAccountResult.data.tenantId;

            // Force-refresh tokens.
            await AccountService.reload().then(() => void 0).catch(() => void 0);

            // Stop loader.
            this.loading = false;

            // Redirect to Profile.
            // This redirect is necessary in order to reload the store and give the backend some time.
            // It known it's funny but it is what it is.
            let self = this;
            setTimeout(function () {
                window.location.replace(self.newTenantAccountAppUrl);
            }, 1500);

            // // Make request to Accounts to sign up new account.
            // TenantAccountService.createTenantAccount(dto).then(({ data }) => {
            //     // this.activeStep = 3; // Increase step. This field is explanatory.
            //     this.createSuccess = true;
            //
            //     // Set the new TenantAccount URL.
            //     this.newTenantAccountAppUrl = CLOUTLAYER_WEBAPP_BASE_URL + '/' + data.alias;
            //
            //     // Redirect to Profile.
            //     // This redirect is necessary in order to reload the store and give the backend some time.
            //     // It known it's funny but it is what it is.
            //     let self = this;
            //     setTimeout(function () {
            //         window.location.replace(self.newTenantAccountAppUrl);
            //     }, 1500);
            // }).catch((reason) => {
            //     // Set error.
            //     this.createError = reason;
            //
            //     if (reason.dataMessage === 'error.accountaliasexists') {
            //         this.aliasExists = true;
            //     }
            // }).finally(() => {
            //     // Stop Loader.
            //     this.loading = false;
            // });
        }
    }
};
</script>
